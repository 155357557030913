/** @jsx jsx */
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Box, Grid, jsx, Text } from "theme-ui"
import BookCover from "./book-cover"
import Layout from "./layout"
import LinkButton from "./link-button"
import SEO from "./seo"
import Title from "./title"

const BookComponent = ({ book }) => (
  <Layout>
    <SEO title={book.title} description={book.overview} pathname={book.slug} />
    <Grid gap={4} columns={[1, 1, 2]}>
      <Box>
        <BookCover fluid={book.cover.childImageSharp.fluid} />
      </Box>
      <Grid columns={1}>
        <Box>
          <Title text={book.title} as="h2">
            <Text variant="styles.subtitle">{book.subtitle}</Text>
          </Title>
        </Box>
        <Box>
          <Text variant="styles.p">{book.overview}</Text>
        </Box>
        <Box sx={{ margin: "0 auto" }}>
          <LinkButton externalLink={book.url}>Buy Now</LinkButton>
        </Box>
      </Grid>
    </Grid>
    <section
      sx={{
        my: [4, 4, 5],
        ".gatsby-resp-image-wrapper": {
          my: [3, 3, 5],
        },
      }}
    >
      <MDXRenderer>{book.body}</MDXRenderer>
    </section>
  </Layout>
)

const Book = ({ data }) => {
  const { book } = data

  return <BookComponent book={book} />
}

export default Book
