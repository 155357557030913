import { graphql } from "gatsby"
import BookComponent from "../components/book"

export default BookComponent

export const query = graphql`
  query($slug: String!, $formatString: String!) {
    book(slug: { eq: $slug }) {
      slug
      title
      subtitle
      overview
      url
      date(formatString: $formatString)
      cover {
        childImageSharp {
          fluid(maxWidth: 840) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      body
    }
  }
`
